import {mapActions, mapGetters, mapMutations} from "vuex";
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)
import { Carousel, Slide } from 'vue-carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vivus from 'vivus';
import Timer from "../../../../components/timer/index.vue";
export default {
  name: "promo",
  components:{
    Carousel,
    Slide,
    Timer
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchContent('main-slider')
      }
    }
  },
  data(){
    return{
      width: true
    }
  },
  created() {
    this.fetchContent('main-slider')

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
    if(window.innerWidth <= 900){
      this.width = false
    }
  },
  computed:{
    ...mapGetters({
      contentList: 'pages/bannerContent'
    }),
  },
  methods:{
    ...mapActions({
      fetchContent: 'pages/GET_BANNER_CONTENT'
    }),
  }
}