import {mapMutations} from "vuex";
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vivus from 'vivus';

export default {
  name: "about",
  components:{
  },
  data(){
    return{

    }
  },
  created() {

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{

  },
  methods:{
    ...mapMutations({
    }),
    visibilityChanged (isVisible, entry) {
      if(isVisible === true){
        new Vivus('womanizer', {
          duration: 200,
          start: 'autostart',
        });
      }
    },
  }
}