export default {
  name: 'timer',
  props: {
    deadline: {
      type: String,
      required: true
    },
    speed: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      currentTime: null
    };
  },
  mounted() {
    setTimeout(this.countdown, 1);
  },
  methods: {
    countdown() {
      let date1 = Date.parse(this.parseDateString(this.deadline));
      let t = date1 - Date.parse(new Date());
      let seconds = Math.floor((t / 1000) % 60);
      let minutes = Math.floor((t / 1000 / 60) % 60);
      let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      let days = Math.floor(t / (1000 * 60 * 60 * 24));
      if (t > 0) {
        this.currentTime = {
          total: t,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        };
        setTimeout(this.countdown, this.speed);
      } else {
        this.currentTime = null;
      }
    },
    parseDateString (dateString) {
      var matchers = [];
      matchers.push(/^[0-9]*$/.source);
      matchers.push(/([0-9]{1,2}\/){2}[0-9]{4}( [0-9]{1,2}(:[0-9]{2}){2})?/.source);
      matchers.push(/[0-9]{4}([\/\-][0-9]{1,2}){2}( [0-9]{1,2}(:[0-9]{2}){2})?/.source);
      matchers = new RegExp(matchers.join("|"));
      if (dateString instanceof Date) {
        return dateString;
      }
      if (String(dateString).match(matchers)) {
        if (String(dateString).match(/^[0-9]*$/)) {
          dateString = Number(dateString);
        }
        if (String(dateString).match(/\-/)) {
          dateString = String(dateString).replace(/\-/g, "/");
        }
        return new Date(dateString);
      } else {
        throw new Error("Couldn't cast `" + dateString + "` to a date object.");
      }
    }
  },
}
