import {mapActions, mapGetters, mapMutations} from "vuex";

import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: "preview",
  components:{

  },
  data(){
    return{
      wedhover: false,
      wedImage: '',
      evehover: false,
      eveImage: '',
    }
  },
  created() {
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      categoriesList: 'category/categoriesList'
    }),
    eveningCollectionList(){
      let arr = []
      for (let i in this.categoriesList){
        if(this.categoriesList[i].typeCategorySlug === 'evening'){
          arr.push(this.categoriesList[i])
        }
      }
      return arr
    },
    weddingCollectionList(){
      let arr = []
      for (let i in this.categoriesList){
        if(this.categoriesList[i].typeCategorySlug === 'wedding'){
          arr.push(this.categoriesList[i])
        }
      }
      return arr
    }
  },
  methods:{
    ...mapMutations({
    }),
    ...mapActions({}),
    changeWedImage(type, item){
      if(type){
        this.wedImage = item.image
        this.wedhover = true
      }
      else{
        this.wedhover = false
      }
    },
    changeEveImage(type, item){
      if(type){
        this.eveImage = item.image
        this.evehover = true
      }
      else{
        this.evehover = false
      }
    },
  }
}