import {mapMutations} from "vuex";
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vivus from 'vivus';

export default {
  name: "design",
  components:{
  },
  data(){
    return{

    }
  },
  created() {

  },
  computed:{

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods:{
    ...mapMutations({
    }),
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      if(entry.isIntersecting === true){
        new Vivus('sign', {duration: 400});
      }
    }
  }
}