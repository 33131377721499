import {mapActions, mapGetters, mapMutations} from "vuex";

import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: "design",
  components: {},
  watch: {
    '$route'(newVal) {
      if (newVal) {
        this.fetchContent()
      }
    }
  },
  data() {
    return {}
  },
  created() {
    this.fetchContent()

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed: {
    ...mapGetters({
      contentList: 'pages/magazines'
    }),
  },
  methods: {
    ...mapActions({
      fetchContent: 'pages/GET_MAGAZINES'
    }),
  }
}