import {mapMutations} from "vuex";

//sections

export default {
  name: "show",
  components:{
  },
  data(){
    return{
      play: true,
    }
  },
  created() {
  },
  computed:{

  },
  mounted() {
  },
  methods:{
    ...mapMutations({
    })
  }
}