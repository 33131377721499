import {mapMutations} from "vuex";

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import aboutSection from '@/modules/home/section/about/index.vue';
import designSection from '@/modules/home/section/design/index.vue';
import previewSection from '@/modules/home/section/preview/index.vue';
import newsSection from '@/modules/home/section/home-news/index.vue';
import show from '@/modules/home/section/show/index.vue';
import AOS from "aos";

export default {
  name: "home",
  components:{
    promoSection,
    aboutSection,
    designSection,
    previewSection,
    newsSection,
    show,
  },
  data(){
    return{
      width: false,
    }
  },
  created() {
  },
  computed:{

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
    window.innerWidth <= 700 ? this.width = true : this.width = false
  },
  methods:{
    ...mapMutations({
    })
  }
}